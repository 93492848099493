import { useEffect, useState } from "react";
import { usePathname } from "next/navigation";
import { isClient } from "../utils";
import { useAnalyticsContext } from "./AnalyticsProvider";
import {
  createLandingPageView,
  createOnboardingPageView,
  createProfilePageView,
  createNikeSignInCompletedEvent,
  createAccountSettingsPageView,
} from "./analyticsEventsCreators";
import {
  ROUTE_JOIN,
  ROUTE_HOME,
  ROUTE_ACCOUNT_SETTINGS,
  ROUTE_PROFILE,
} from "@/constants/routes";
import { ONBOARDING_SIGN_IN_COMPLETED_SESSION_KEY } from "@/constants/app";
import useIsAuth from "../hooks/useIsAuth";
import { get } from "lodash";
import { PAGE_TYPE, PageTrackFunctionType } from "./types";
import { eventPayloadCreator } from "nvs-analytics";

export const trackPageView = (event: any) => {
  if (isClient()) {
    window.analyticsClient?.page(
      event.name,
      { ...event.properties },
      { ...event.options },
    );
  }
  return event.properties.view;
};

export const trackEvent = (event: any) => {
  if (isClient()) {
    window.analyticsClient?.track(
      event.name,
      { ...event.properties },
      { ...event.options },
    );
  }
  return event.properties.view;
};

const pageViewFunctionTracker = {
  [ROUTE_HOME]: createLandingPageView,
  [ROUTE_PROFILE]: createProfilePageView,
  [ROUTE_ACCOUNT_SETTINGS]: createAccountSettingsPageView,
  [ROUTE_JOIN]: createOnboardingPageView,
};

export const usePageView = () => {
  const {
    setPreviousView,
    currentUser,
    previousView,
    country,
    isCurrentUserLoading,
    isReady,
  } = useAnalyticsContext();
  const pathname = usePathname();
  useEffect(() => {
    if (!isCurrentUserLoading && isReady) {
      const trackFunction = get(
        pageViewFunctionTracker,
        pathname!,
      ) as PageTrackFunctionType;
      if (trackFunction) {
        setPreviousView(
          trackPageView(trackFunction(previousView, currentUser, country)),
        );
      }
    }
  }, [pathname, isCurrentUserLoading, isReady]);
};

export const useCheckSignInCompleted = () => {
  const analyticsContext = useAnalyticsContext();
  const isAuthed = useIsAuth();
  let signInTimeLogged = false;

  isClient()
    ? (signInTimeLogged =
        sessionStorage.getItem(ONBOARDING_SIGN_IN_COMPLETED_SESSION_KEY) !==
        null)
    : (signInTimeLogged = false);

  useEffect(() => {
    if (isAuthed && signInTimeLogged) {
      sessionStorage.removeItem(ONBOARDING_SIGN_IN_COMPLETED_SESSION_KEY);
      analyticsContext?.setPreviousView(
        trackEvent(
          createNikeSignInCompletedEvent(
            analyticsContext?.currentUser,
            analyticsContext?.previousView,
            analyticsContext?.country,
          ),
        ),
      );
    }
  }, [isAuthed, signInTimeLogged]);
};

export function useTrackEvent<T>(
  payloadCreator: ReturnType<typeof eventPayloadCreator<T>>,
) {
  const analyticsContext = useAnalyticsContext();
  const currentUser = analyticsContext.currentUser;
  let memberNumber = "";
  if (currentUser?.membershipCard?.memberNumber)
    memberNumber = currentUser?.membershipCard?.memberNumber.toString();
  const user = {
    upmId: currentUser?.upmId as string,
    swooshHandle: currentUser?.swooshHandle as string,
    memberNumber: memberNumber,
  };
  const country = analyticsContext.country as string;
  const anonymousId = analyticsContext.anonymousId as string;
  const os = analyticsContext.os as { name: string; version: string };
  const previousView = analyticsContext.previousView as {
    experienceType: "nvs";
    pageDetail: string;
    pageType: PAGE_TYPE;
    pageName: string;
  };

  // we'll return a function that takes the payload specific event and passes it the the analytics track function
  return (payload: T) => {
    analyticsContext?.setPreviousView(
      trackEvent(
        payloadCreator(
          { user, country, anonymousId, os, previousView },
          payload,
        ),
      ),
    );
  };
}

export function usePageEvent<T>(
  payloadCreator: ReturnType<typeof eventPayloadCreator<T>>,
) {
  const [eventFired, setEventFired] = useState<boolean>(false);
  const analyticsContext = useAnalyticsContext();
  const currentUser = analyticsContext.currentUser;
  let memberNumber = "";
  if (currentUser?.membershipCard?.memberNumber)
    memberNumber = currentUser?.membershipCard?.memberNumber.toString();
  const user = {
    upmId: currentUser?.upmId as string,
    swooshHandle: currentUser?.swooshHandle as string,
    memberNumber: memberNumber,
  };
  const country = analyticsContext.country as string;
  const anonymousId = analyticsContext.anonymousId as string;
  const os = analyticsContext.os as { name: string; version: string };
  const previousView = analyticsContext.previousView as {
    experienceType: "nvs";
    pageDetail: string;
    pageType: PAGE_TYPE;
    pageName: string;
  };

  // we'll return a function that takes the payload specific event and passes it the the analytics page function
  return (payload: T) => {
    // in case this function is wrapped in a useEffect,
    // we want to make sure this function is only really fired one time
    if (!eventFired) {
      analyticsContext?.setPreviousView(
        trackPageView(
          payloadCreator(
            { user, country, anonymousId, os, previousView },
            payload,
          ),
        ),
      );
      setEventFired(true);
    }
  };
}

export function usePageViewEventWithReset<T>(
  payloadCreator: ReturnType<typeof eventPayloadCreator<T>>,
) {
  const [eventFired, setEventFired] = useState<boolean>(false);
  const analyticsContext = useAnalyticsContext();
  const currentUser = analyticsContext.currentUser;
  let memberNumber = "";
  if (currentUser?.membershipCard?.memberNumber)
    memberNumber = currentUser?.membershipCard?.memberNumber.toString();
  const user = {
    upmId: currentUser?.upmId as string,
    swooshHandle: currentUser?.swooshHandle as string,
    memberNumber: memberNumber,
  };
  const country = analyticsContext.country as string;
  const anonymousId = analyticsContext.anonymousId as string;
  const os = analyticsContext.os as { name: string; version: string };
  const previousView = analyticsContext.previousView as {
    experienceType: "nvs";
    pageDetail: string;
    pageType: PAGE_TYPE;
    pageName: string;
  };

  // we'll return a function that takes the payload specific event and passes it the the analytics page function
  return {
    pageView: (payload: T) => {
      // in case this function is wrapped in a useEffect,
      // we want to make sure this function is only really fired one time
      if (!eventFired) {
        analyticsContext?.setPreviousView(
          trackPageView(
            payloadCreator(
              { user, country, anonymousId, os, previousView },
              payload,
            ),
          ),
        );
        setEventFired(true);
      }
    },
    setEventFired, // allow for reset
  };
}

export function useFirePageView<T>(
  payloadCreator: ReturnType<typeof eventPayloadCreator<T>>,
  payload: T,
  isTrack: boolean,
  isReady: boolean,
) {
  const [analyticsCaptured, setAnalyticsCaptured] = useState(false);
  const analyticsContext = useAnalyticsContext();
  const currentUser = analyticsContext.currentUser;
  const user = {
    upmId: currentUser?.upmId as string,
    swooshHandle: currentUser?.swooshHandle as string,
    memberNumber: currentUser?.membershipCard?.memberNumber + "",
  };
  const country = analyticsContext.country as string;
  const anonymousId = analyticsContext.anonymousId as string;
  const os = analyticsContext.os as { name: string; version: string };
  const previousView = analyticsContext.previousView as {
    experienceType: "nvs";
    pageDetail: string;
    pageType: PAGE_TYPE;
    pageName: string;
  };

  //@TODO NVS-3537: test if this correctly re-renders on payload dependency.
  //Note: Seems like sometimes events fire prematurely with undefined data 🤔.

  useEffect(() => {
    // If we have already triggered an analytics event and the hook re-renders just return
    if (analyticsCaptured || !isReady) return;

    isTrack
      ? (analyticsContext?.setPreviousView(
          trackEvent(
            payloadCreator(
              { user, country, anonymousId, os, previousView },
              payload,
            ),
          ),
        ),
        setAnalyticsCaptured(true))
      : (analyticsContext?.setPreviousView(
          trackPageView(
            payloadCreator(
              { user, country, anonymousId, os, previousView },
              payload,
            ),
          ),
        ),
        setAnalyticsCaptured(true));
  }, [analyticsCaptured, payload]);
  return { analyticsCaptured };
}
