import {
  EXTERNAL_LINK_DOT_SWOOSH_MEDIUM_BLOG,
  EXTERNAL_LINK_DOT_SWOOSH_INSTAGRAM,
  EXTERNAL_LINK_DOT_SWOOSH_TWITTER,
  EXTERNAL_LINK_CONTACT_US,
} from "@/constants/app";
import {
  ROUTE_LEGAL_PRIVACY_POLICY,
  ROUTE_LEGAL_TERMS_OF_SERVICE,
} from "@/constants/routes";
import { FooterItems, FooterItemType } from "./types";

export const leftFooterItems: FooterItemType[] = [
  {
    title: "blog",
    key: `${FooterItems.LINK.toString()}-BLOG`,
    link: EXTERNAL_LINK_DOT_SWOOSH_MEDIUM_BLOG,
  },
  {
    title: "instagram",
    key: `${FooterItems.LINK.toString()}-INSTAGRAM`,
    link: EXTERNAL_LINK_DOT_SWOOSH_INSTAGRAM,
  },
  {
    title: "twitter",
    key: `${FooterItems.LINK.toString()}-TWITTER`,
    link: EXTERNAL_LINK_DOT_SWOOSH_TWITTER,
  },
];

export const rightFooterItems: FooterItemType[] = [
  {
    title: `.SWOOSH Terms of service`,
    key: FooterItems.TERMS_OF_SERVICE,
    link: ROUTE_LEGAL_TERMS_OF_SERVICE,
  },
  {
    title: ".SWOOSH Privacy Policy",
    key: FooterItems.PRIVACY_POLICY,
    link: ROUTE_LEGAL_PRIVACY_POLICY,
  },
  {
    title: "Cookie Settings",
    key: FooterItems.COOKIE_SETTINGS,
  },
  {
    title: "Contact Us",
    key: FooterItems.CONTACT_US,
    link: EXTERNAL_LINK_CONTACT_US,
  },
];
