import { useIsMobile } from "@/libs/utils";
import clsx from "clsx";
import { noop } from "lodash";
import { createContext, useContext } from "react";
import { CloseButtonProps, toast, ToastContainer } from "react-toastify";
import { IconButton } from "nvs-design-system/dist/IconButton";
import { IconButtonType } from "nvs-design-system/dist/IconButton/types";
import { IconContainer, IconSize } from "../icons/components/IconContainer";
import { BaseToastProps } from "./types";
import { Close } from "nvs-ds-icons/react/navigation";
import { CheckCircle, AlertCircle } from "nvs-ds-icons/react/core";

export type ToastShape = {
  createToast: (props: BaseToastProps) => void;
};

export const ToastContext = createContext<ToastShape>({
  createToast: noop,
});

export const useToastContext = () => useContext(ToastContext);

type ToastProviderProps = {
  children: React.ReactNode;
};

const ToastProvider = (props: ToastProviderProps) => {
  const isMobile = useIsMobile();
  const createToast = ({
    ToastComponent,
    icon,
    type,
    theme,
    ...props
  }: BaseToastProps) => {
    toast(<>{ToastComponent}</>, {
      icon:
        icon == undefined ? ( // allow for custom icon
          <IconContainer>
            {type === "success" ? <CheckCircle /> : <AlertCircle />}
          </IconContainer>
        ) : (
          icon
        ),
      theme: theme,
      position: isMobile ? "bottom-center" : "center",
      bodyClassName: clsx(theme == "dark" && type !== "error" && "dark"),
      type: type,
      hideProgressBar: true,
      ...props,
    });
  };
  return (
    <ToastContext.Provider value={{ createToast }}>
      <ToastContainer
        closeButton={CloseToastButton}
        className={clsx("toastThemeColors")}
      />
      {props.children}
    </ToastContext.Provider>
  );
};

const CloseToastButton: React.FC<CloseButtonProps> = ({ closeToast, type }) => (
  <div id="close-button" className={clsx("flex items-center pr-2")}>
    <IconButton
      buttonType={IconButtonType.no_outline}
      aria-label="Close Toast"
      id="close-button"
      className={clsx(
        "flex h-fit items-center",
        type === "error" && "hover:bg-transparent",
      )}
      title="Close"
      onClick={closeToast}
      iconContainerProps={{ size: IconSize.xs }}
    >
      <Close />
    </IconButton>
  </div>
);

export default ToastProvider;
