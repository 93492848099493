// eslint-disable-next-line @typescript-eslint/no-var-requires
const plugin = require("tailwindcss/plugin");

module.exports = {
  content: [
    "./app/**/*.{js,ts,jsx,tsx}",
    "./pages/**/*.{js,ts,jsx,tsx}",
    "./src/**/*.{js,ts,jsx,tsx}",
    "./components/**/*.{js,ts,jsx,tsx}",
    // NVS Design System components
    "./node_modules/nvs-design-system/**/*.{js,ts,jsx,tsx}",
  ],
  darkMode: "class",
  theme: {
    // Override default tailwind theme colors
    colors: {
      transparent: "transparent",
      current: "currentColor",
      white: "#ffffff",
      cream: "#EEEDE4",
      "off-white": "#CAC8BD",
      black: "#000000",
      overlay: "rgba(17, 17, 17, 0.84)",

      // New design system color scales
      gray: {
        50: "#FAFAFA",
        100: "#F5F5F5",
        200: "#E5E5E5",
        300: "#CACACB",
        350: "#B2B2B2",
        400: "#9E9EA0",
        425: "#8B8B8B",
        450: "#757575",
        500: "#707072",
        600: "#4B4B4D",
        700: "#39393B",
        800: "#28282A",
        900: "#1F1F21",
        950: "#111111",
      },
      red: {
        50: "#FFE5E5",
        300: "#FE5000",
        400: "#FF4D29",
        500: "#FF0015",
        600: "#EE0005",
        700: "#D43F21",
      },
      green: {
        50: "#B7FF2C",
        500: "#41A139",
        600: "#128A09",
        700: "#1EAA52",
      },
      blue: {
        400: "#8CABFF",
        500: "#3369FF",
        600: "#244AB2",
        700: "#4072FF",
      },
      orange: {
        50: "#FCA600",
      },

      // Colors specific to product badges
      neon: {
        purple: "#B68BFF",
        blue: "#1EF9DF",
        pink: "#FE49F7",
        yellow: "#C3FF17",
        green: "#05FF00",
      },

      // Colors below are deprecated and will be deleted
      deprecated_gridboxgray: "#B3B2AB",
      deprecated_pink: "#df50b6",
      deprecated_violet: "#863cf1",
      deprecated_green: "#68ea8c",
      deprecated_limegreen: "#cbff36",
      deprecated_deepblue: "#2d69f8",
      deprecated_lightblue: "#2dc2f7",
    },

    extend: {
      keyframes: {
        "to-left": {
          "0%": { transform: "translateX(0)" },
          "100%": { transform: "translateX(-100%)" },
        },
        "to-right-full": {
          "0%": { transform: "translateX(-100%)" },
          "100%": { transform: "translateX(100%)" },
        },
        "gradient-diagonal": {
          "0%, 100%": {
            "background-size": "200% 200%",
            "background-position": "left top",
          },
          "50%": {
            "background-size": "200% 200%",
            "background-position": "right bottom",
          },
        },
      },
      animation: {
        "ticker-left": "to-left 40s linear infinite",
        "loading-bar-pulse": "to-right-full 1s ease-in-out infinite",
        "gradient-diagonal": "gradient-diagonal 1s ease-in-out infinite",
        blink: "pulse 1s step-end infinite",
      },
      backgroundSize: {
        "200%": "200% 200%",
      },
      backgroundImage: {
        "gradient-radial": "radial-gradient(var(--tw-gradient-stops))",
      },
      boxShadow: {
        // NOTE: For <HighlightHeadlines /> component
        "border-1": "inset 0 0 0 1px #707072",
      },
      screens: {
        960: "960px",
        mobile: "320px",
        tablet: "540px",
        laptop: "769px",
        desktop: "1025px",
        xldesktop: "1280px",
        1110: "1110px", // breakpoint where footer goes from desktop -> tablet
        1440: "1440px", // TODO: make this an actual screen size
        xxldesktop: "1690px",
        "3xl": "1920px", // max nav width
        betterhover: { raw: "(hover: hover)" },
      },
      spacing: {
        // These are meant to be the most common spacing values in the design system
        // If you need a different spacing values, use the default tailwind spacing values
        xxs: "0.25rem", // 4px
        xs: "0.5rem", // 8px
        sm: "0.75rem", // 12px
        base: "1.5rem", // 24px == 1 grid unit
        md: "2rem", // 32px
        lg: "3rem", // 48px
        xl: "3.5rem", // 56px
        "2xl": "4.5rem", // 72px
        "3xl": "6rem", // 96px
        "4xl": "9rem", // 144px
        "5xl": "12rem", // 192px
        "6xl": "15rem", // 240px

        // deprecated and will be deleted when onboarding design is updated
        sitePaddingMobile: "var(--site-padding-mobile)",
        sitePaddingLaptop: "var(--site-padding-laptop)",
        sitePaddingMobileFull: "var(--site-padding-mobile-full)",
        sitePaddingLaptopFull: "var(--site-padding-laptop-full)",
      },
      minWidth: {
        nav: "52px",
      },
      zIndex: {
        1: 1,
        multiBuyButton: 40,
        animationUnderHeaderFooter: 49,
        header: 50,
        footer: 50,
        filters: 100,
        filterButton: 101,
        tooltip: 101,
        globalNav: 200,
        modal: 500,
        filtersOpen: 600,
        filterButtonOpen: 601,
        cookieBanner: 1000,
        animationScreen: 1001,
      },
      lineHeight: {
        // The complete system of line heights matching the Design System
        100: 1, // 100%
        110: 1.1, // 110%
        120: 1.2, // 120%
        130: 1.3, // 120%
        140: 1.4, // 140%
        150: 1.5, // 150%
      },
      transitionDuration: {
        0: "0ms",
        1500: "1500ms",
        2000: "2000ms",
      },
      fontFamily: {
        roobert: "Roobert, Helvetica, Arial, sans-serif",
        "helvetica-mono": `"Helvetica Monospaced Pro", monospace`,

        // Deprecated themes:
        primary: "var(--font-primary)",
        secondary: "var(--font-secondary)",
        marketplace: "var(--font-marketplace)",
        emphasis: "var(--font-emphasis)",
      },
      textColor: {
        primary: "var(--color-text-primary)",
        default: "var(--color-text-primary)",
        secondary: "var(--color-text-secondary)",
        inverse: "var(--color-text-inverse)",
        "inverse-soft": "var(--color-text-inverse-soft)",
        emphasis: "var(--color-text-emphasis)",
        error: "var(--color-text-error)",
        form: "var(--color-text-form)",
        disabledform: "var(--color-disabled-gray-on-light)",
      },
      backgroundColor: {
        primary: "var(--color-bg-primary)",
        default: "var(--color-bg-primary)",
        secondary: "var(--color-bg-secondary)",
        inverse: "var(--color-bg-inverse)",
        modal: "var(--color-bg-modal)",
      },
      borderColor: {
        primary: "var(--color-border-primary)",
        secondary: "var(--color-border-secondary)",
        error: "var(--color-border-error)",
        form: "var(--color-border-form)",
        disabledform: "var(--color-border-form-disabled)",
      },
      // Default NVS Prose color scheme
      // @see https://github.com/tailwindlabs/tailwindcss-typography
      typography: ({ theme }) => ({
        nvs: {
          css: {
            "--tw-prose-invert-body": theme("colors.white"),
            "--tw-prose-invert-headings": theme("colors.white"),
            "--tw-prose-invert-lead": theme("colors.white"),
            "--tw-prose-invert-links": theme("colors.blue[500]"),
            "--tw-prose-invert-bold": theme("colors.white"),
            "--tw-prose-invert-counters": theme("colors.white"),
            "--tw-prose-invert-bullets": theme("colors.white"),
            "--tw-prose-invert-hr": theme("colors.gray[500]"),
            "--tw-prose-invert-quotes": theme("colors.white"),
            "--tw-prose-invert-quote-borders": theme("colors.gray[500]"),
            "--tw-prose-invert-captions": theme("colors.white"),
            "--tw-prose-invert-code": theme("colors.white"),
            "--tw-prose-invert-pre-code": theme("colors.white"),
            "--tw-prose-invert-pre-bg": theme("colors.white"),
            "--tw-prose-invert-th-borders": theme("colors.gray[500]"),
            "--tw-prose-invert-td-borders": theme("colors.gray[500]"),
            "--tw-prose-body": theme("colors.gray[950]"),
            "--tw-prose-headings": theme("colors.gray[950]"),
            "--tw-prose-lead": theme("colors.gray[950]"),
            "--tw-prose-links": theme("colors.blue[500]"),
            "--tw-prose-bold": theme("colors.gray[950]"),
            "--tw-prose-counters": theme("colors.gray[950]"),
            "--tw-prose-bullets": theme("colors.gray[950]"),
            "--tw-prose-hr": theme("colors.gray[500]"),
            "--tw-prose-quotes": theme("colors.gray[950]"),
            "--tw-prose-quote-borders": theme("colors.gray[500]"),
            "--tw-prose-captions": theme("colors.gray[950]"),
            "--tw-prose-code": theme("colors.gray[950]"),
            "--tw-prose-pre-code": theme("colors.gray[950]"),
            "--tw-prose-pre-bg": "rgb(0 0 0 / 50%)",
            "--tw-prose-th-borders": theme("colors.gray[500]"),
            "--tw-prose-td-borders": theme("colors.gray[500]"),
          },
        },
      }),
    },
    cursor: {
      auto: "auto",
      default: "default",
      pointer: "pointer",
      wait: "wait",
      text: "text",
      move: "move",
      "not-allowed": "not-allowed",
      crosshair: "crosshair",
      "zoom-in": "zoom-in",
    },
    letterSpacing: {
      // The complete system of letter spacing/tracking matching the Design System
      // NOTE: You should only use this on fonts that do not require
      // custom ligatures (Helvetica Mono) as it will break the ligatures.
      "1-percent": `${1 / 1000}em`,
    },
    fontSize: {
      // The complete system of sizes matching the Design System
      // 1rem = 16px
      // =======================================================
      xxs: "0.625rem", // 10px
      xs: "0.75rem", // 12px
      sm: "0.875rem", // 14px
      base: "1rem", // 16px
      md: "1.125rem", // 18px
      lg: "1.25rem", // 20px
      xl: "1.5rem", // 24px
      "2xl": "1.75rem", // 28px
      "3xl": "2rem", // 32px
      "4xl": "2.25rem", // 36px
      "5xl": "2.5rem", // 40px
      "6xl": "3rem", // 48px
      "7xl": "3.375rem", // 54px
      "8xl": "4rem", // 64px
      "9xl": "5rem", // 80px
      "10xl": "7.5rem", // 120px

      // Deprecated - only used in the onboarding designs
      "11xl": "9.25rem", // 148px - was 7xl

      // Deprecated - only used in the onboarding designs
      // NOTE: these scale with the viewport width and are clamped to meet the
      // min/max defined in breakpoints for non-fluid headline sizes
      "fluid-headline-xxs":
        "clamp(1rem, calc(4vw - var(--site-padding-responsive)), 1.25rem)",
      "fluid-headline-xs":
        "clamp(1.125rem, calc(5vw - var(--site-padding-responsive)), 1.5rem)",
      "fluid-headline-sm":
        "clamp(1.25rem, calc(6vw - var(--site-padding-responsive)), 2rem)",
      "fluid-headline-base":
        "clamp(1.5rem, calc(7vw - var(--site-padding-responsive)), 3rem)",
      "fluid-headline-md":
        "clamp(2rem, calc(8vw - var(--site-padding-responsive)), 4rem)",
      "fluid-headline-lg":
        "clamp(3rem, calc(11vw - var(--site-padding-responsive)), 5rem)",
      "fluid-headline-xl":
        "clamp(4rem, calc(12vw - var(--site-padding-responsive)), 7.5rem)",
    },

    // NOTE: These aspect ratios help position items to our 8/12 col grid
    aspectRatio: {
      "12/11": "12/11",
      "12/7": "12/7",
      "12/5": "12/5",
      "12/1": "12/1",

      "7/5": "7/5",
      "7/4": "7/4",
      "7/3": "7/3",
      "5/4": "5/4",

      "6/5": "6/5",
      "6/1": "6/1",

      "4/3": "4/3",
      "4/1": "4/1",
      "3/2": "3/2",
      "3/1": "3/1",
      "2/1": "2/1",

      poster: "0.632/1", // 3D canvas poster ratio

      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
      6: "6",
      7: "7",
      8: "8",
      9: "9",
      10: "10",
      11: "11",
      12: "12",
    },
  },
  variants: {
    extend: {
      opacity: ["hover", "group-hover"],
    },
  },
  plugins: [
    require("@tailwindcss/typography"),
    plugin(function ({ addVariant }) {
      // Custom selection variant
      // 'is-selected' is a custom class that can be added to any element
      // then the modifier can be used to style the selected state
      // 'selected:bg-blue-500'
      addVariant("selected", "&.is-selected");
    }),
  ],
};
