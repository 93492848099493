import { NAV_BAR_SIGN_COMPLETED_SESSION_KEY } from "@/constants/app";
import {
  createJoinClickedGlobalNavEvent,
  createSignInToNikeEvent,
} from "@/libs/analytics/analyticsEventsCreators";
import { useAnalyticsContext } from "@/libs/analytics/AnalyticsProvider";
import { trackEvent } from "@/libs/analytics/useAnalytics";
import { signIn } from "next-auth/react";
import { useCreateCallbackUrl } from "./hooks";
import { PAGE_TYPE } from "nvs-analytics/dist/constants";

export const useHandleSignInClicked = (
  sessionKey: string,
  pageType: PAGE_TYPE,
  callbackUrl?: string,
) => {
  const defaultCallbackUrl = useCreateCallbackUrl();
  const analyticsSignIn = useSignInAnalytics(pageType);
  return () => {
    analyticsSignIn();
    sessionStorage.setItem(sessionKey, "" + Date.now());
    signIn("nike", {
      callbackUrl: callbackUrl ?? defaultCallbackUrl,
    });
  };
};

const useSignInAnalytics = (pageType: PAGE_TYPE, eventName?: string) => {
  const { setPreviousView, previousView, currentUser, country } =
    useAnalyticsContext();
  return () =>
    setPreviousView(
      trackEvent(
        createSignInToNikeEvent(
          currentUser,
          previousView,
          pageType,
          country,
          eventName,
        ),
      ),
    );
};

export const useHandleSignInClickedGlobalNav = (callbackUrl?: string) => {
  const defaultCallbackUrl = useCreateCallbackUrl();

  const analyticsSignIn = useSignInAnalytics(PAGE_TYPE.globalNav);

  return () => {
    analyticsSignIn();
    sessionStorage.setItem(NAV_BAR_SIGN_COMPLETED_SESSION_KEY, "" + Date.now());

    signIn("nike", {
      callbackUrl: callbackUrl ?? defaultCallbackUrl,
    });
  };
};

export const useHandleJoinClickedGlobalNavAnalytics = () => {
  const { setPreviousView, previousView, currentUser, country } =
    useAnalyticsContext();
  return () => {
    setPreviousView(
      trackEvent(
        createJoinClickedGlobalNavEvent(currentUser, previousView, country),
      ),
    );
  };
};
