import React, { useState } from "react";
import clsx from "clsx";

type Props = React.PropsWithChildren<{
  id: string;
  name: string;
  allowChange?: boolean; // this is for a non disabled input thats required but can't be changed but should still be accessible
  hasError?: boolean;
  classes?: {
    input?: string;
    label?: string;
    outerDiv?: string;
  };
}> &
  React.InputHTMLAttributes<HTMLInputElement>;

// Note if you want a non changable field you have to mark input as non changable

const StyledCheckbox = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      children,
      onChange,
      hasError,
      classes,
      allowChange = true,
      disabled = false,
      required = false,
      ...props
    },
    ref,
  ) => {
    const [checked, setChecked] = useState<boolean>(
      props.checked ?? props.defaultChecked ?? false,
    );
    const notDisabledOrError = !disabled && !hasError;
    return (
      <div
        className={clsx(
          "relative inline-flex w-full flex-row items-center",
          classes?.outerDiv,
        )}
      >
        <input
          className={clsx("peer absolute opacity-0", classes?.input)}
          type={"checkbox"}
          id={id}
          ref={ref}
          disabled={disabled}
          onChange={(e) => {
            onChange && onChange(e);
            allowChange && setChecked(e.target.checked);
          }}
          required={required}
          {...props}
        />
        <label
          htmlFor={id}
          className={clsx(
            // position outer box
            "before:absolute before:top-[0.15rem] laptop:before:top-[0.35rem] before:left-0 before:inline-block",
            "laptop:before:h-4 laptop:before:w-4 before:h-5 before:w-5", // outerbox size
            notDisabledOrError &&
              checked &&
              "before:border-gray-950 before:bg-white",
            notDisabledOrError &&
              !checked &&
              "before:bg-gray-950 before:border-white",
            hasError && "before:bg-primary before:border-red-400",
            disabled && "before:bg-primary before:border-gray-450",
            "before:border",
            // position checkmark
            "after:top-[0.45rem] laptop:after:top-[0.65rem] after:left-1",
            "after:absolute after:inline-block",
            "laptop:after:h-1 laptop:after:w-2 after:h-1.5 after:w-3", // checkmark size
            // checkmark style
            "after:border-l-2 after:border-b-2",
            "after:-rotate-45",
            "after:content-none", // hide checkmark when unchecked
            "after:peer-checked:content-['']", // content: "" is required to make the checkmark visible when checked
            "focus:outline-none before:peer-focus:border-blue-500 before:peer-focus:ring", // accessible on focus
            notDisabledOrError &&
              checked &&
              "after:bg-white after:border-gray-950",
            notDisabledOrError &&
              !checked &&
              "after:border-white after:bg-primary",
            hasError && "after:bg-primary after:border-red-400",
            disabled && "after:bg-primary after:border-gray-450",
            "laptop:pl-6 relative min-h-[32px] cursor-pointer select-none pl-6", // style actual label
            classes?.label,
          )}
        >
          {children}
        </label>
      </div>
    );
  },
);

StyledCheckbox.displayName = "Checkbox";

export default StyledCheckbox;
