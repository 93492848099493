import React from "react";
import GeneralError from "./GeneralError";
import { Button } from "nvs-design-system/dist/Button";
import { FallbackProps } from "react-error-boundary";
import { useMountEffect } from "@react-hookz/web";
import { logger } from "@/libs/utils";
import useAddTheme from "@/libs/hooks/useAddTheme";
import { get } from "lodash";
import { ERROR_CODES } from "@/libs/error/types";
import AirdropPageNotFound from "../routes/404";
import GridLayout from "./Layout/GridLayout";

const AppErrorBoundary: React.FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  useAddTheme("theme-airdrop"); // fallback on the off case a page does not have the right theme set
  useMountEffect(() => {
    if (window !== undefined) {
      window.newrelic?.noticeError(error);
    }
    logger.error(error.message);
  });

  const statusCode = get(error, "response.status") || get(error, "code");
  const notFound =
    statusCode === ERROR_CODES.BAD_REQUEST ||
    statusCode === ERROR_CODES.NOT_FOUND;

  return (
    /*TODO:NVS-3255 Clean up hardcoded country: */
    <GridLayout>
      {notFound ? (
        <AirdropPageNotFound />
      ) : (
        <GeneralError>
          <Button
            size="sm"
            className={"md:max-w-[327px] max-w-[249px] md:text-xl"}
            onClick={resetErrorBoundary}
          >
            Try Again
          </Button>
        </GeneralError>
      )}
    </GridLayout>
  );
};

export default AppErrorBoundary;
