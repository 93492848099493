import { useEffect } from "react";

// NOTE: Themes are deprecated in favor of Tailwind's dark mode.
// We will remove them completely once onboarding and form components are updated.

type Theme = "theme-onboarding" | "theme-profile" | "theme-airdrop";

const useAddTheme = (theme: Theme) => {
  useEffect(() => {
    document.getElementsByTagName("html")[0].classList.add(theme);
    return () =>
      document.getElementsByTagName("html")[0].classList.remove(theme);
  }, [theme]);
};

export default useAddTheme;
