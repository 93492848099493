import { User } from "../schema/user";

export type PreviousView = {
  experienceType: string;
  pageDetail: string;
  pageType: string;
  pageName: string;
};

export type PageTrackFunctionType = (
  previousView: PreviousView,
  currentUser?: Partial<User>,
  country?: string,
) => PreviousView;

export const EXPERIENCE_TYPE = "nvs";

export enum PAGE_TYPE {
  landing = "landing",
  onboarding = "onboarding",
  profile = "profile",
  cdp = "cdp",
  globalNav = "global-nav",
  tlp = "tlp",
  pdp = "pdp",
  checkout = "checkout",
  reveal = "reveal",
  settings = "settings",
  clp = "clp",
  gallery = "gallery",
  achievements = "achievements",
  orderHistory = "order-history",
  glitchGalaxy = "glitch-galaxy",
  marketing = "marketing",
  marketing3DModel = "marketing 3d model",
}

export enum CLASSIFICATION_TYPE {
  coreBuyFlow = "core-buy-flow",
  experienceEvent = "experience-event",
}

export enum EVENT_TYPE {
  page = "page",
  track = "track",
}

export type CollectionProperties = {
  slug: string | undefined | null;
  chainId: number | undefined | null;
  name: string | undefined | null;
  contractAddress: string | undefined | null;
};

export type ProductProperties = {
  price: number | undefined | null;
  productType: string | undefined | null;
  name: string | undefined | null;
  productId: string | undefined | null;
};

export type OrderProperties = {
  orderType: string;
  orderId: string;
};

export type LaunchProperties = {
  launchId?: string;
  launchType: "Airdrop" | "Claim" | "Buy" | "Reveal" | "Sale";
  launchStatus?: "staged" | "active" | "ended";
};

export type TokenProperties = {
  tokenId: string | string[] | undefined;
  name: string | undefined;
  utility?: any;
  activeUtilityCount?: number;
  ownerHandle: string | undefined;
  favorites?: number;
  isListed?: boolean;
};

export type ListingProperties = {
  created?: string;
  expires?: string;
  price?: string;
};
