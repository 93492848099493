import clsx from "clsx";
import React from "react";
import OnboardingCustomButton, {
  OnboardingCustomButtonProps,
} from "../OnboardingCustomButton";
import styles from "./index.module.css";

// DEPRECATED - Do not use this.
// Use the <Button> at src/button/index.tsx instead.

type Theme = "dark" | "light";

type Style = "inverse" | "primary";

type Props = OnboardingCustomButtonProps & {
  style?: Style;
  theme?: Theme; // not using a theme will default to higher theming
};

const buttonStyling = {
  inverse:
    "text-secondary bg-secondary border-secondary betterhover:hover:text-primary betterhover:hover:border-primary betterhover:hover:bg-primary",
  primary:
    "text-primary bg-primary border-primary betterhover:hover:text-secondary betterhover:hover:border-secondary betterhover:hover:bg-secondary",
};

const OnboardingSecondaryButton: React.FC<Props> = ({
  theme,
  style = "primary",
  classes,
  ...props
}) => {
  return (
    <OnboardingCustomButton
      {...props}
      classes={clsx(
        theme && styles[theme],
        buttonStyling[style],
        classes,
        "border",
      )}
    ></OnboardingCustomButton>
  );
};

export default OnboardingSecondaryButton;
