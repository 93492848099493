import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import clsx from "clsx";

type Props = React.PropsWithChildren<{
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  backgroundClassName?: string;
}>;

const BaseModalTransition: React.FC<Props> = ({
  children,
  isOpen,
  onClose,
  className = "",
  backgroundClassName = "bg-primary bg-opacity-30",
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={clsx("z-modal fixed", className)}
        onClose={onClose}
        open={isOpen}
      >
        {/* Alters opacity behind and blacks out background behind modal */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-[.84]"
          leave="ease-in duration-100"
          leaveFrom="opacity-[.84]"
          leaveTo="opacity-0"
        >
          <div
            className={clsx(
              "fixed inset-0 transition-opacity",
              backgroundClassName,
            )}
          />
        </Transition.Child>

        {children}
      </Dialog>
    </Transition>
  );
};

export default BaseModalTransition;
