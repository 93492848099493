import clsx from "clsx";
import { PropsWithChildren } from "react";
import { ButtonSize, ButtonType } from "nvs-design-system/dist/Button/types";
import {
  getClassNamesByType,
  getIconSizeByButtonSize,
} from "nvs-design-system/dist/Button/helpers";
import { TextNoWrapEllipsis } from "nvs-design-system/dist/TextNoWrapEllipsis";
import Link from "next/link";
import { IconContainer } from "../../../icons/components/IconContainer";
import { LinkButtonProps } from "./types";

export function LinkButton({
  className,
  icon,
  size = ButtonSize.md,
  buttonType = ButtonType.primary,
  display = "inline-flex",
  scroll = true,
  ...props
}: PropsWithChildren<LinkButtonProps>) {
  return (
    <Link
      scroll={scroll}
      className={clsx(
        className,
        display,
        getClassNamesByType(buttonType, size),
      )}
      {...props}
    >
      <TextNoWrapEllipsis>{props.children}</TextNoWrapEllipsis>
      {icon && (
        <IconContainer
          size={getIconSizeByButtonSize(size)}
          display="inline-block"
          className="ml-auto shrink-0"
        >
          {icon}
        </IconContainer>
      )}
    </Link>
  );
}
