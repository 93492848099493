export const CURRENT_USER = "currentUser";
export const CURRENT_USER_ROLE = "currentUserRole";
export const WAITLIST = "waitlistStatus";
export const USER_WALLETS = "wallets";
export const USER_NFTS = "nfts";
export const USER_MINTS = "mints";
export const PROFILES = "profiles";
export const QUERY_KEY_USER_KYC_STATUS = "kycStatus";
export const PRODUCTS = "products";
export const COLLECTIONS = "collections";
export const COLLECTIONS_DETAILS_PAGE = "collectionsDetailsPage";
export const COLLECTIONS_LISTING_PAGE = "collectionsListingPage";
export const COLLECTION_METADATA = "collection-metadata";
export const TOKENS = "tokens";
export const METADATA = "metadata";
export const TOKEN_ATTRIBUTES = "attributes";
export const LANDING_PAGE = "landing-page";
/// TODO in refactor authorable Global Nav PR: remove this key vv
export const GLOBAL_NAV_CONTENT = "global-nav";
////
export const GLOBAL_STATUS_BANNER = "global-status-banner";
export const COLLECTION_SALE_WINDOWS = "collectionSaleWindows";
export const PRODUCT_SALE_WINDOWS = "productSaleWindows";
export const ORDERS = "orders";
export const FAVORITES = "favorites";
export const INFINITE_FAVORITES = "infinite-favorites";
export const CONTENTFUL = "contentful";
export const NIKE_PARTNERS = "nikePartners";
// TODO: NVS-3014 fix up query keys for favorites
export const INFINITE_FAVORITES_USER = "infinite-favorites-user";
export const PROFILE_FAVORITES = "profile-favorites";
export const USER_ACHIEVEMENTS = "achievements";
export const GLOBAL_NAV_HEADERS = "global-nav-headers";
export const GLOBAL_NAV_DROPDOWN_MENUS = "global-nav-dropdown-menus";

// preorder
export const SHOP_PRODUCTS_BY_SLUG = "shop-products-by-slug";
export const SHOP_PRODUCTS_BY_ID = "shop-products-by-id";
export const SHOP_PRODUCTS_BY_MULTIPLE_IDS = "shop-products-by-multiple-ids";
export const SHOP_PRODUCT_CONFIRMED_ORDERS = "shop-products-confirmed-orders";
export const SHOP_PRODUCT_USER_ORDERS = "shop-products-user-orders";

// using a separate id for the polling than the order confirmation
// for polling
export const SHOP_PRODUCT_ORDER_DETAILS = "shop-product-order-details";
// for confirmation
export const SHOP_PRODUCT_ORDER_CONFIRMATION =
  "shop-product-order-confirmation-details";

export const RECOMMENDED_PRODUCTS = "recommended-products";
export const DOCUMENT = "document";
